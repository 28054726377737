<template>
  <LandingContent isSplash />
</template>

<script>
import LandingContent from "../components/UI/landing/LandingContent.vue";
export default {
  components: { LandingContent }
};
</script>

<style lang="scss" scoped></style>
